<template>
  <div class="posts-list">
    <template v-if="eventList && eventList.length > 0">
      <EventCardComponent v-for="post in eventList" :key="'post_' + post.PostId" :data="post" />
    </template>

    <NoDataFoundComponent v-else :size="'sm'" type="full" :title="$t.getTranslation('LBL_EVENTS_EMPTY')" />
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, inject, defineAsyncComponent } from "vue";
import { get } from "@/utils/axios";
import { useStore } from "@/store";

// import EventCardComponent from "@/components/cards/EventCardComponent.vue";
// import NoDataFoundComponent from "@/components/NoDataFoundComponent.vue";

const EventCardComponent = defineAsyncComponent(() => import(/* webpackChunkName: "event-card" */ /* webpackMode: "lazy" */ "@/components/cards/EventCardComponent.vue"));
const NoDataFoundComponent = defineAsyncComponent(() => import(/* webpackChunkName: "no-data" */ /* webpackMode: "lazy" */ "@/components/NoDataFoundComponent.vue"));

export default defineComponent({
  name: "EventListComponent",
  components: {
    EventCardComponent,
    NoDataFoundComponent,
  },
  props: {
    data: Object,
    mode: String,
    size: { type: Number, default: 6 },
    userkey: String,
  },
  setup(props) {
    const store = useStore();

    const $t = inject("$translation");

    let page = 1;
    let lastPage = 1;

    const searchValue = ref("");

    const eventList = ref([]);

    const getEventList = async () => {
      let ret = await get("/event/list", {
        LanguageCode: $t.getLanguage(),
        mode: props.mode,
        page: page,
        size: props.size,
        UserKey: props.userkey,
        SearchField: "MULTIPLE",
        SearchType: "LIKE",
        SearchValue: searchValue.value,
      });

      if (ret && ret.data && Array.isArray(ret.data)) {
        for (let counter = 0; counter < ret.data.length; counter++) {
          let item = ret.data[counter];
          eventList.value.push(item);
        }

        lastPage = ret?.lastPage;
        page++;
      }
    };

    const loadMore = () => {
      return lastPage >= page ? getEventList() : false;
    };

    const search = (data) => {
      searchValue.value = data;
      eventList.value = [];
      page = 1;

      getEventList();
    };

    onMounted(() => {
      getEventList();
    });

    return { eventList, loadMore, search, store };
  },
});
</script>
